var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "header-mobile align-items-center header-mobile-fixed",
      attrs: { id: "tc_header_mobile" }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex align-items-center" }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn p-0", attrs: { id: "tc_aside_mobile_toggle" } },
          [
            _c(
              "svg",
              {
                staticClass: "bi bi-justify-right",
                attrs: {
                  width: "20px",
                  height: "20px",
                  viewBox: "0 0 16 16",
                  fill: "currentColor",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    "fill-rule": "evenodd",
                    d:
                      "M6 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-4-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"
                  }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn p-0 ml-2",
            attrs: { id: "tc_header_mobile_topbar_toggle" }
          },
          [
            _c("span", { staticClass: "svg-icon svg-icon-xl" }, [
              _c(
                "svg",
                {
                  staticClass: "bi bi-person-fill",
                  attrs: {
                    width: "20px",
                    height: "20px",
                    viewBox: "0 0 16 16",
                    fill: "currentColor",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      d:
                        "M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                    }
                  })
                ]
              )
            ])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "brand-logo", attrs: { href: "/admin/dashboard" } },
      [
        _c("span", { staticClass: "brand-text" }, [
          _c("img", {
            staticStyle: { height: "25px" },
            attrs: { alt: "Logo", src: "/assets/images/misc/logo.png" }
          })
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "posicon" }, [
      _c(
        "a",
        {
          staticClass:
            "btn btn-primary d-flex align-items-center justify-content-center white mr-2",
          attrs: { href: "pos.html" }
        },
        [_vm._v("POS")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }