var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "d-flex flex-column-fluid" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card card-custom gutter-b bg-transparent shadow-none border-0"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card-header align-items-center  border-bottom-dark px-0"
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("div", { staticClass: "icons d-flex" }, [
                            _vm.$parent.permissions.includes(
                              "slider-bannder-manage"
                            )
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn ml-2 p-0 kt_notes_panel_toggle",
                                    attrs: {
                                      "data-toggle": "tooltip",
                                      title: "",
                                      "data-placement": "right",
                                      "data-original-title":
                                        "Check out more demos"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "bg-secondary h-30px font-size-h5 w-30px d-flex align-items-center justify-content-center  rounded-circle shadow-sm ",
                                        on: {
                                          click: function($event) {
                                            _vm.display_form = !_vm.display_form
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "bi bi-plus white",
                                            attrs: {
                                              width: "25px",
                                              height: "25px",
                                              viewBox: "0 0 16 16",
                                              fill: "currentColor",
                                              xmlns:
                                                "http://www.w3.org/2000/svg"
                                            }
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "fill-rule": "evenodd",
                                                d:
                                                  "M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      )
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 " }, [
                  _c(
                    "div",
                    {
                      staticClass: "card card-custom gutter-b bg-white border-0"
                    },
                    [
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: " table-responsive",
                              attrs: { id: "printableTable" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "dataTables_wrapper no-footer",
                                  attrs: { id: "productsliderTable_wrapper" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dataTables_length",
                                      attrs: { id: "productsliderTable_length" }
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          "Show\r\n                                                        "
                                        ),
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.limit,
                                                expression: "limit"
                                              }
                                            ],
                                            attrs: {
                                              name: "productsliderTable_length",
                                              "aria-controls":
                                                "productsliderTable"
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.limit = $event.target
                                                    .multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                },
                                                function($event) {
                                                  return _vm.fetchSliders()
                                                }
                                              ]
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "10" } },
                                              [_vm._v("10")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "25" } },
                                              [_vm._v("25")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "50" } },
                                              [_vm._v("50")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "100" } },
                                              [_vm._v("100")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "200" } },
                                              [_vm._v("200")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "500" } },
                                              [_vm._v("500")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "option",
                                              { attrs: { value: "1000" } },
                                              [_vm._v("1000")]
                                            )
                                          ]
                                        ),
                                        _vm._v(" entries")
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "dataTables_filter",
                                      attrs: { id: "productsliderTable_filter" }
                                    },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          "\r\n                                                    Search:"
                                        ),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.searchParameter,
                                              expression: "searchParameter"
                                            }
                                          ],
                                          attrs: {
                                            type: "search",
                                            placeholder: "",
                                            "aria-controls":
                                              "productsliderTable"
                                          },
                                          domProps: {
                                            value: _vm.searchParameter
                                          },
                                          on: {
                                            keyup: function($event) {
                                              return _vm.fetchSliders()
                                            },
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.searchParameter =
                                                $event.target.value
                                            }
                                          }
                                        })
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "display dataTable no-footer",
                                      attrs: {
                                        id: "productsliderTable",
                                        role: "grid"
                                      }
                                    },
                                    [
                                      _c(
                                        "thead",
                                        { staticClass: "text-body" },
                                        [
                                          _c("tr", { attrs: { role: "row" } }, [
                                            _c(
                                              "th",
                                              {
                                                staticClass: "sorting",
                                                class:
                                                  (this.$data.sortType ==
                                                    "asc" ||
                                                    this.$data.sortType ==
                                                      "ASC") &&
                                                  this.$data.sortBy == "id"
                                                    ? "sorting_asc"
                                                    : (this.$data.sortType ==
                                                        "desc" ||
                                                        this.$data.sortType ==
                                                          "DESC") &&
                                                      this.$data.sortBy == "id"
                                                    ? "sorting_desc"
                                                    : "sorting",
                                                staticStyle: {
                                                  width: "31.25px"
                                                },
                                                attrs: {
                                                  tabindex: "0",
                                                  "aria-controls":
                                                    "productsliderTable",
                                                  rowspan: "1",
                                                  colspan: "1",
                                                  "aria-sort": "ascending",
                                                  "aria-label":
                                                    "ID: activate to sort column descending"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.sorting("id")
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\r\n                                                                ID\r\n                                                            "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "sorting",
                                                class:
                                                  (this.$data.sortType ==
                                                    "asc" ||
                                                    this.$data.sortType ==
                                                      "ASC") &&
                                                  this.$data.sortBy == "title"
                                                    ? "sorting_asc"
                                                    : (this.$data.sortType ==
                                                        "desc" ||
                                                        this.$data.sortType ==
                                                          "DESC") &&
                                                      this.$data.sortBy ==
                                                        "title"
                                                    ? "sorting_desc"
                                                    : "sorting",
                                                staticStyle: {
                                                  width: "95.5288px"
                                                },
                                                attrs: {
                                                  tabindex: "0",
                                                  "aria-controls":
                                                    "productsliderTable",
                                                  rowspan: "1",
                                                  colspan: "1",
                                                  "aria-label":
                                                    "slider: activate to sort column ascending"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.sorting("title")
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\r\n                                                                Title\r\n                                                            "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              {
                                                staticClass: "sorting",
                                                class:
                                                  (this.$data.sortType ==
                                                    "asc" ||
                                                    this.$data.sortType ==
                                                      "ASC") &&
                                                  this.$data.sortBy ==
                                                    "description"
                                                    ? "sorting_asc"
                                                    : (this.$data.sortType ==
                                                        "desc" ||
                                                        this.$data.sortType ==
                                                          "DESC") &&
                                                      this.$data.sortBy ==
                                                        "description"
                                                    ? "sorting_desc"
                                                    : "sorting",
                                                staticStyle: {
                                                  width: "95.5288px"
                                                },
                                                attrs: {
                                                  tabindex: "0",
                                                  "aria-controls":
                                                    "productsliderTable",
                                                  rowspan: "1",
                                                  colspan: "1",
                                                  "aria-label":
                                                    "slider: activate to sort column ascending"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.sorting(
                                                      "description"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\r\n                                                                Description\r\n                                                            "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              {
                                                staticClass:
                                                  "no-sort sorting_disabled",
                                                staticStyle: {
                                                  width: "53.1891px"
                                                },
                                                attrs: {
                                                  rowspan: "1",
                                                  colspan: "1",
                                                  "aria-label": "Action"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\r\n                                                                Slider Type\r\n                                                            "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              {
                                                staticClass:
                                                  "no-sort sorting_disabled",
                                                staticStyle: {
                                                  width: "53.1891px"
                                                },
                                                attrs: {
                                                  rowspan: "1",
                                                  colspan: "1",
                                                  "aria-label": "Action"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\r\n                                                                Slider Image\r\n                                                            "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.$parent.permissions.includes(
                                              "slider-bannder-manage"
                                            )
                                              ? _c(
                                                  "th",
                                                  {
                                                    staticClass:
                                                      "no-sort sorting_disabled",
                                                    staticStyle: {
                                                      width: "53.1891px"
                                                    },
                                                    attrs: {
                                                      rowspan: "1",
                                                      colspan: "1",
                                                      "aria-label": "Action"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\r\n                                                                Action\r\n                                                            "
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        {
                                          staticClass:
                                            "kt-table-tbody text-dark"
                                        },
                                        _vm._l(_vm.sliders, function(slider) {
                                          return _c(
                                            "tr",
                                            {
                                              key: slider.slider_id,
                                              staticClass:
                                                "kt-table-row kt-table-row-level-0 odd",
                                              attrs: { role: "row" }
                                            },
                                            [
                                              _c(
                                                "td",
                                                { staticClass: "sorting_1" },
                                                [
                                                  _vm._v(
                                                    "\r\n                                                                " +
                                                      _vm._s(slider.slider_id) +
                                                      "\r\n                                                            "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\r\n                                                                " +
                                                    _vm._s(
                                                      slider.slider_title
                                                    ) +
                                                    "\r\n                                                            "
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\r\n                                                                " +
                                                    _vm._s(
                                                      slider.slider_description
                                                    ) +
                                                    "\r\n                                                            "
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                _vm._v(
                                                  "\r\n                                                                " +
                                                    _vm._s(
                                                      slider.slider_type == null
                                                        ? ""
                                                        : slider.slider_type
                                                            .slider_type_name
                                                    ) +
                                                    "\r\n                                                            "
                                                )
                                              ]),
                                              _vm._v(" "),
                                              _c("td", [
                                                slider.gallary != null
                                                  ? _c("img", {
                                                      staticClass:
                                                        "img-thumbnail",
                                                      attrs: {
                                                        src:
                                                          "/gallary/thumbnail" +
                                                          slider.gallary
                                                            .gallary_name,
                                                        alt: "image not found"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ]),
                                              _vm._v(" "),
                                              _vm.$parent.permissions.includes(
                                                "slider-bannder-manage"
                                              )
                                                ? _c("td", [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          " click-edit",
                                                        attrs: {
                                                          href:
                                                            "javascript:void(0)",
                                                          id: "click-edit1",
                                                          "data-toggle":
                                                            "tooltip",
                                                          title: "",
                                                          "data-placement":
                                                            "right",
                                                          "data-original-title":
                                                            "Check out more demos"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.editSlider(
                                                              slider
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-edit"
                                                        })
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.deleteSlider(
                                                              slider.slider_id
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-trash"
                                                        })
                                                      ]
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "pagination pagination-sm m-0 float-right"
                                    },
                                    [
                                      _c(
                                        "li",
                                        {
                                          class: [
                                            {
                                              disabled: !_vm.pagination
                                                .prev_page_url
                                            }
                                          ]
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "page-link",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.fetchSliders(
                                                    _vm.pagination.prev_page_url
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Previous")]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("li", { staticClass: "disabled" }, [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "page-link text-dark",
                                            attrs: { href: "#" }
                                          },
                                          [
                                            _vm._v(
                                              "Page " +
                                                _vm._s(
                                                  _vm.pagination.current_page
                                                ) +
                                                " of " +
                                                _vm._s(_vm.pagination.last_page)
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "li",
                                        {
                                          staticClass: "page-item",
                                          class: [
                                            {
                                              disabled: !_vm.pagination
                                                .next_page_url
                                            }
                                          ]
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "page-link",
                                              attrs: { href: "#" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.fetchSliders(
                                                    _vm.pagination.next_page_url
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("Next")]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.display_form
        ? _c(
            "div",
            {
              staticClass:
                "offcanvas offcanvas-right kt-color-panel p-5 kt_notes_panel",
              class: _vm.display_form ? "offcanvas-on" : ""
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "offcanvas-header d-flex align-items-center justify-content-between pb-3"
                },
                [
                  _c(
                    "h4",
                    { staticClass: "font-size-h4 font-weight-bold m-0" },
                    [_vm._v("Add slider")]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn btn-sm btn-icon btn-light btn-hover-primary kt_notes_panel_close",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          return _vm.clearForm()
                        }
                      }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "bi bi-x",
                          attrs: {
                            width: "20px",
                            height: "20px",
                            viewBox: "0 0 16 16",
                            fill: "currentColor",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              d:
                                "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c("form", { attrs: { id: "myform" } }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "form-group " }, [
                      _c("label", { staticClass: "text-dark" }, [
                        _vm._v("slider Name")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.slider.title,
                            expression: "slider.title"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.slider.title },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.slider, "title", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.has("title")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("title"))
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group " }, [
                      _c("label", { staticClass: "text-dark" }, [
                        _vm._v("Description")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.slider.description,
                            expression: "slider.description"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text" },
                        domProps: { value: _vm.slider.description },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.slider,
                              "description",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.errors.has("description")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("description"))
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group " }, [
                      _c("label", { staticClass: "text-dark" }, [
                        _vm._v("Position")
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.slider.position,
                              expression: "slider.position"
                            }
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.slider,
                                "position",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "position-left" } }, [
                            _vm._v("left")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "position-right" } }, [
                            _vm._v("right")
                          ]),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { value: "position-center" } },
                            [_vm._v("center")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.errors.has("position")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("position"))
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group " }, [
                      _c("label", { staticClass: "text-dark" }, [
                        _vm._v("Text Content Position")
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.slider.textcontent,
                              expression: "slider.textcontent"
                            }
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.slider,
                                "textcontent",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { value: "textcontent-left" },
                              domProps: {
                                selected:
                                  _vm.slider.textcontent == "textcontent-left"
                              }
                            },
                            [_vm._v("left")]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            {
                              attrs: { value: "textcontent-right" },
                              domProps: {
                                selected:
                                  _vm.slider.textcontent == "textcontent-right"
                              }
                            },
                            [_vm._v("right")]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            {
                              attrs: { value: "textcontent-center" },
                              domProps: {
                                selected:
                                  _vm.slider.textcontent == "textcontent-center"
                              }
                            },
                            [_vm._v("center")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.errors.has("textcontent")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("description"))
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group " }, [
                      _c("label", { staticClass: "text-dark" }, [
                        _vm._v("Text Color")
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.slider.text,
                              expression: "slider.text"
                            }
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.slider,
                                "text",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "text-black" } }, [
                            _vm._v("black")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "text-white" } }, [
                            _vm._v("white")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _vm.errors.has("text")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("description"))
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.toggleImageSelect()
                            }
                          }
                        },
                        [_vm._v("Upload slider Media")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "clearfix" }),
                      _vm._v(" "),
                      _vm.gallary_path == null || _vm.gallary_path == ""
                        ? _c(
                            "small",
                            {
                              staticClass: "form-text text-muted",
                              attrs: { id: "textHelp" }
                            },
                            [_vm._v("Select Image file from gallary.")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errors.has("gallary_id")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("gallary_id"))
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.gallary_path != ""
                        ? _c("img", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: { src: _vm.gallary_path }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "text-dark" }, [
                        _vm._v("Language ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.slider.language_id,
                              expression: "slider.language_id"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.slider,
                                "language_id",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("Select Language")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.languages, function(language) {
                            return _c(
                              "option",
                              { domProps: { value: language.id } },
                              [
                                _vm._v(
                                  "\r\n                        " +
                                    _vm._s(language.language_name) +
                                    "\r\n                        "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.errors.has("language_id")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(_vm.errors.get("language_id"))
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "text-dark" }, [
                        _vm._v("Slider type ")
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.slider.slider_type_id,
                              expression: "slider.slider_type_id"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.slider,
                                "slider_type_id",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("Select Slider type")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.slider_types, function(slider_type) {
                            return _c(
                              "option",
                              {
                                domProps: { value: slider_type.slider_type_id }
                              },
                              [
                                _vm._v(
                                  "\r\n                        " +
                                    _vm._s(slider_type.slider_type_name) +
                                    "\r\n                        "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.errors.has("slider_type_id")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.errors.get("slider_type_id")
                              )
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "text-dark" }, [
                        _vm._v("Slider Navigation ")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "clearfix" }),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.slider.slider_navigation_id,
                              expression: "slider.slider_navigation_id"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.slider,
                                "slider_navigation_id",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("Select Slider Navigation")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.slider_navigations, function(
                            slider_navigation
                          ) {
                            return _c(
                              "option",
                              {
                                domProps: {
                                  value: slider_navigation.slider_navigation_id
                                }
                              },
                              [
                                _vm._v(
                                  "\r\n                        " +
                                    _vm._s(
                                      slider_navigation.slider_navigation_name
                                    ) +
                                    "\r\n                        "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.errors.has("slider_navigation_id")
                        ? _c("small", {
                            staticClass: "form-text text-danger",
                            domProps: {
                              textContent: _vm._s(
                                _vm.errors.get("slider_navigation_id")
                              )
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.slider.slider_navigation_id == 1
                      ? _c("div", { staticClass: "form-group" }, [
                          _c("label", { staticClass: "text-dark" }, [
                            _vm._v("Category ")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "clearfix" }),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.slider.ref_id,
                                  expression: "slider.ref_id"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.slider,
                                    "ref_id",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Select Category")
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.categories, function(category) {
                                return _c(
                                  "option",
                                  { domProps: { value: category.id } },
                                  [
                                    _vm._v(
                                      "\r\n                        " +
                                        _vm._s(
                                          category.detail == null
                                            ? ""
                                            : category.detail[0].name
                                        ) +
                                        "\r\n                        "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.errors.has("ref_id")
                            ? _c("small", {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(_vm.errors.get("ref_id"))
                                }
                              })
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.slider.slider_navigation_id == 2
                      ? _c("div", { staticClass: "form-group" }, [
                          _c("label", { staticClass: "text-dark" }, [
                            _vm._v("Products ")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "clearfix" }),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.slider.ref_id,
                                  expression: "slider.ref_id"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.slider,
                                    "ref_id",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Select Product")
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.products, function(product) {
                                return _c(
                                  "option",
                                  { domProps: { value: product.product_id } },
                                  [
                                    _vm._v(
                                      "\r\n                        " +
                                        _vm._s(
                                          product.detail == null
                                            ? ""
                                            : product.detail[0].title
                                        ) +
                                        "\r\n                        "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.errors.has("ref_id")
                            ? _c("small", {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(_vm.errors.get("ref_id"))
                                }
                              })
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.slider.slider_navigation_id == 3
                      ? _c("div", { staticClass: "form-group" }, [
                          _c("label", { staticClass: "text-dark" }, [
                            _vm._v("Pages ")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "clearfix" }),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.slider.ref_id,
                                  expression: "slider.ref_id"
                                }
                              ],
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.slider,
                                    "ref_id",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v("Select Page")
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.pages, function(page) {
                                return _c(
                                  "option",
                                  { domProps: { value: page.id } },
                                  [
                                    _vm._v(
                                      "\r\n                            " +
                                        _vm._s(page.slug) +
                                        "\r\n                        "
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.errors.has("ref_id")
                            ? _c("small", {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(_vm.errors.get("ref_id"))
                                }
                              })
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.slider.slider_navigation_id == 4
                      ? _c("div", { staticClass: "form-group" }, [
                          _c("label", { staticClass: "text-dark" }, [
                            _vm._v("Link ")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "clearfix" }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.slider.url,
                                expression: "slider.url"
                              }
                            ],
                            attrs: { type: "text", name: _vm.slider.url },
                            domProps: { value: _vm.slider.url },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(_vm.slider, "url", $event.target.value)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.has("url")
                            ? _c("small", {
                                staticClass: "form-text text-danger",
                                domProps: {
                                  textContent: _vm._s(_vm.errors.get("url"))
                                }
                              })
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.addUpdateSlider()
                      }
                    }
                  },
                  [_vm._v("Submit")]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("attach-image", {
        attrs: { showModal: _vm.showModal },
        on: { toggleImageSelect: _vm.toggleImageSelect, setImage: _vm.setImage }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-title mb-0" }, [
      _c("h3", { staticClass: "card-label mb-0 font-weight-bold text-body" }, [
        _vm._v(
          "\r\n                                            Slider Setting\r\n                                        "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }