var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column-fluid" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
              _c(
                "div",
                {
                  staticClass:
                    "\n                  card card-custom\n                  gutter-b\n                  bg-transparent\n                  shadow-none\n                  border-0\n                "
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "card-header align-items-center border-bottom-dark px-0"
                    },
                    [
                      _c("div", { staticClass: "card-title mb-0" }, [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "card-label mb-0 font-weight-bold text-body"
                          },
                          [
                            _vm._v(
                              "\n                      Order\n                      "
                            ),
                            _c("span", { staticClass: "text-black-50" }, [
                              _vm._v("#" + _vm._s(_vm.orders.order_id))
                            ])
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "icons d-flex" })
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "card-body pb-0 pt-4" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center" },
                      [
                        _c(
                          "ul",
                          {
                            staticClass: "nav nav-pills mb-0",
                            attrs: { id: "pills-tab", role: "tablist" }
                          },
                          [
                            _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link active",
                                  attrs: {
                                    id: "detail-tab",
                                    "data-toggle": "pill",
                                    href: "#detail",
                                    role: "tab",
                                    "aria-controls": "detail",
                                    "aria-selected": "true"
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-file-earmark-text",
                                      attrs: {
                                        width: "20px",
                                        height: "20px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M5 11.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v("\n                          Detail")
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    id: "invoice-tab",
                                    "data-toggle": "pill",
                                    href: "#invoice",
                                    role: "tab",
                                    "aria-controls": "invoice",
                                    "aria-selected": "false"
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-receipt",
                                      attrs: {
                                        width: "20px",
                                        height: "20px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                          Invoice\n                        "
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    id: "status-tab",
                                    "data-toggle": "pill",
                                    href: "#status",
                                    role: "tab",
                                    "aria-controls": "status",
                                    "aria-selected": "false"
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-check2-square",
                                      attrs: {
                                        width: "20px",
                                        height: "20px",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M15.354 2.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L8 9.293l6.646-6.647a.5.5 0 0 1 .708 0z"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M1.5 13A1.5 1.5 0 0 0 3 14.5h10a1.5 1.5 0 0 0 1.5-1.5V8a.5.5 0 0 0-1 0v5a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V3a.5.5 0 0 1 .5-.5h8a.5.5 0 0 0 0-1H3A1.5 1.5 0 0 0 1.5 3v10z"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                          Status\n                        "
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _vm.setting.is_deliveryboyapp_purchased === "1"
                              ? _c("li", { staticClass: "nav-item" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "nav-link",
                                      attrs: {
                                        id: "delivery-tab",
                                        "data-toggle": "pill",
                                        href: "#delivery",
                                        role: "tab",
                                        "aria-controls": "delivery",
                                        "aria-selected": "false"
                                      }
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticClass: "bi bi-clock-delivery",
                                          attrs: {
                                            width: "1em",
                                            height: "1em",
                                            viewBox: "0 0 16 16",
                                            fill: "currentColor",
                                            xmlns: "http://www.w3.org/2000/svg"
                                          }
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              "fill-rule": "evenodd",
                                              d:
                                                "M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("path", {
                                            attrs: {
                                              "fill-rule": "evenodd",
                                              d:
                                                "M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("path", {
                                            attrs: {
                                              "fill-rule": "evenodd",
                                              d:
                                                "M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"
                                            }
                                          })
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                          Delivery\n                        "
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    id: "notes-tab",
                                    "data-toggle": "pill",
                                    href: "#notes",
                                    role: "tab",
                                    "aria-controls": "notes",
                                    "aria-selected": "false"
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-clock-notes",
                                      attrs: {
                                        width: "1em",
                                        height: "1em",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                          Notes\n                        "
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "nav-item" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  attrs: {
                                    id: "message-tab",
                                    "data-toggle": "pill",
                                    href: "#message",
                                    role: "tab",
                                    "aria-controls": "message",
                                    "aria-selected": "false"
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      staticClass: "bi bi-clock-notes",
                                      attrs: {
                                        width: "1em",
                                        height: "1em",
                                        viewBox: "0 0 16 16",
                                        fill: "currentColor",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          d:
                                            "M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"
                                        }
                                      })
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                          Message\n                        "
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12 col-xl-12" }, [
              _c(
                "div",
                {
                  staticClass: "tab-content",
                  attrs: { id: "pills-tabContent" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane fade show active",
                      attrs: {
                        id: "detail",
                        role: "tabpanel",
                        "aria-labelledby": "detail-tab"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card card-custom gutter-b bg-white border-0"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "card-header border-bottom align-items-center"
                            },
                            [
                              _vm._m(0),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-xl-8 col-12" }, [
                                _c("div", { staticClass: "user-info mb-2" }, [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "\n                              m-0\n                              p-0\n                              list-style-none\n                              d-flex\n                              justify-content-center\n                            "
                                    },
                                    [
                                      _c(
                                        "li",
                                        {
                                          staticClass:
                                            "d-flex mr-3 align-items-center"
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-body icon" },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "bi bi-person",
                                                  attrs: {
                                                    width: "15px",
                                                    height: "15px",
                                                    viewBox: "0 0 16 16",
                                                    fill: "currentColor",
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "fill-rule": "evenodd",
                                                      d:
                                                        "M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "h5",
                                            {
                                              staticClass:
                                                "\n                                  font-size-h4 font-size-bold\n                                  text-body\n                                  mb-0\n                                  pl-2\n                                  pr-2\n                                "
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Name:\n                                "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-dark font-size-base"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.orders.customer_id
                                                        .customer_first_name +
                                                        " " +
                                                        _vm.orders.customer_id
                                                          .customer_last_name
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "li",
                                        {
                                          staticClass:
                                            "d-flex align-items-center"
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-body icon" },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass: "bi bi-envelope",
                                                  attrs: {
                                                    width: "15px",
                                                    height: "15px",
                                                    viewBox: "0 0 16 16",
                                                    fill: "currentColor",
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "fill-rule": "evenodd",
                                                      d:
                                                        "M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "h5",
                                            {
                                              staticClass:
                                                "\n                                  font-size-h4 font-size-bold\n                                  text-body\n                                  mb-0\n                                  pl-2\n                                  pr-2\n                                "
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Email:\n                                "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-dark font-size-base"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.orders.customer_id
                                                        .customer_email
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "col-xl-2 col-12",
                                  attrs: { id: "third" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-black-50 text-right" },
                                    [
                                      _c(
                                        "h5",
                                        { staticClass: "font-size-h6 mb-0" },
                                        [
                                          _vm._v(
                                            "\n                            Placed On "
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.orders.order_date)
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-body pt-0" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "\n                          row\n                          gutter-pb gutter-pt\n                          border-bottom\n                          order-payment\n                          justify-content-center\n                        "
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 col-lg-6 col-xl-4 payment-detail"
                                  },
                                  [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "\n                              card-label\n                              mb-2\n                              font-weight-bold font-size-h4\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Payment Method\n                          "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      staticClass: "form-control w-250px",
                                      attrs: {
                                        type: "text",
                                        id: "disabledInput1",
                                        placeholder: _vm.orders.payment_method,
                                        disabled: ""
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Transaction ID:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orders.transaction_id
                                                  ? _vm.orders.transaction_id
                                                  : "none"
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Amount:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.orders.order_price)
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 col-lg-6 col-xl-4 payment-detail"
                                  },
                                  [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "\n                              card-label\n                              mb-2\n                              font-weight-bold font-size-h4\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Shipping Method\n                          "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      staticClass: "form-control w-250px",
                                      attrs: {
                                        type: "text",
                                        id: "disabledInput",
                                        placeholder: _vm.orders.shipping_method,
                                        disabled: ""
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Date:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.orders.order_date)
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "\n                          row\n                          gutter-pb gutter-pt\n                          justify-content-center\n                          border-bottom\n                        "
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 col-lg-6 col-xl-4 payment-detail"
                                  },
                                  [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "\n                              card-label\n                              mb-2\n                              font-weight-bold font-size-h4\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Billing Address\n                          "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            First Name:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "text-dark" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orders.billing_first_name
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Last Name:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orders.billing_last_name
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Address:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orders
                                                  .billing_street_aadress
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            City:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.orders.billing_city)
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Country:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orders.billing_country1
                                                  .country_name
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            State:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orders.billing_state1.name
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Zip Code:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orders.billing_postcode
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-5\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Phone:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.orders.billing_phone)
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 col-lg-6 col-xl-4 payment-detail"
                                  },
                                  [
                                    _c(
                                      "h4",
                                      {
                                        staticClass:
                                          "\n                              card-label\n                              mb-2\n                              font-weight-bold font-size-h4\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Shipping Address\n                          "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            First Name:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orders.delivery_first_name
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Last Name:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orders.delivery_last_name
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Address:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orders
                                                  .delivery_street_aadress
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            City:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.orders.delivery_city)
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Country:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orders.delivery_country1
                                                  .country_name
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            State:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orders.delivery_state1.name
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-3\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Zip Code:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orders.delivery_postcode
                                              )
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "\n                              font-size-h5\n                              mt-5\n                              mb-0\n                              font-size-bold\n                              text-body\n                            "
                                      },
                                      [
                                        _vm._v(
                                          "\n                            Phone:\n                            "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-dark font-size-base"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.orders.delivery_phone)
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "col-12 gutter-pt table-padding",
                                  attrs: { id: "printableTable" }
                                },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-striped text-body"
                                    },
                                    [
                                      _vm._m(1),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        { attrs: { id: "order-show-detail" } },
                                        [
                                          _vm._l(
                                            _vm.orders.order_detail,
                                            function(detail) {
                                              return detail.product
                                                .product_type == "variable"
                                                ? _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "d-flex responsive-lay"
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "col-12 col-md-2"
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "img-fluid order-image",
                                                            staticStyle: {
                                                              width: "50px",
                                                              height: "60px"
                                                            },
                                                            attrs: {
                                                              src:
                                                                "/gallary/" +
                                                                detail
                                                                  .product_combination
                                                                  .gallary
                                                                  .gallary_name,
                                                              alt:
                                                                "/gallary/" +
                                                                detail
                                                                  .product_combination
                                                                  .gallary
                                                                  .gallary_name
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "col-12 col-md-3 item-detail-left"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-body"
                                                            },
                                                            [
                                                              _c(
                                                                "h4",
                                                                {
                                                                  staticClass:
                                                                    "order-product-name"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                      " +
                                                                      _vm._s(
                                                                        detail
                                                                          .product
                                                                          .detail[0]
                                                                          .title
                                                                      ) +
                                                                      "\n                                      "
                                                                  ),
                                                                  _vm._l(
                                                                    detail
                                                                      .product_combination
                                                                      .combination,
                                                                    function(
                                                                      variation
                                                                    ) {
                                                                      return _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                        " +
                                                                              _vm._s(
                                                                                variation
                                                                                  .variation
                                                                                  .detail[0]
                                                                                  .name
                                                                              ) +
                                                                              "\n                                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "tag-color col-12 col-md-3 order-price"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                  " +
                                                              _vm._s(
                                                                detail.product_discount
                                                                  ? detail.product_discount
                                                                  : detail.product_price
                                                              ) +
                                                              "\n                                "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "col-12 col-md-2"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-group"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                    " +
                                                                  _vm._s(
                                                                    detail.product_qty
                                                                  ) +
                                                                  "\n                                  "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "tag-s col-12 col-md-2 order-sub-price"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                  " +
                                                              _vm._s(
                                                                detail.product_discount
                                                                  ? detail.product_discount *
                                                                      detail.product_qty
                                                                  : detail.product_price *
                                                                      detail.product_qty
                                                              ) +
                                                              "\n                                "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.orders.order_detail,
                                            function(detail) {
                                              return detail.product
                                                .product_type == "simple"
                                                ? _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "d-flex responsive-lay"
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "col-12 col-md-2"
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "img-fluid order-image",
                                                            staticStyle: {
                                                              width: "50px",
                                                              height: "60px"
                                                            },
                                                            attrs: {
                                                              src:
                                                                "/gallary/" +
                                                                detail.product
                                                                  .product_gallary
                                                                  .gallary_name,
                                                              alt:
                                                                "/gallary/" +
                                                                detail.product
                                                                  .product_gallary
                                                                  .gallary_name
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "col-12 col-md-3 item-detail-left"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-body"
                                                            },
                                                            [
                                                              _c(
                                                                "h4",
                                                                {
                                                                  staticClass:
                                                                    "order-product-name"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                      " +
                                                                      _vm._s(
                                                                        detail
                                                                          .product
                                                                          .detail[0]
                                                                          .title
                                                                      ) +
                                                                      "\n                                    "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "tag-color col-12 col-md-3 order-price"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                  " +
                                                              _vm._s(
                                                                detail.product_discount
                                                                  ? detail.product_discount
                                                                  : detail.product_price
                                                              ) +
                                                              "\n                                "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "col-12 col-md-2"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-group"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                    " +
                                                                  _vm._s(
                                                                    detail.product_qty
                                                                  ) +
                                                                  "\n                                  "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "tag-s col-12 col-md-2 order-sub-price"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                  " +
                                                              _vm._s(
                                                                detail.product_discount
                                                                  ? detail.product_discount *
                                                                      detail.product_qty
                                                                  : detail.product_price *
                                                                      detail.product_qty
                                                              ) +
                                                              "\n                                "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "row justify-content-end" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "col-12 col-md-3" },
                                        [
                                          _c("div", [
                                            _c(
                                              "table",
                                              {
                                                staticClass: "table right-table"
                                              },
                                              [
                                                _c("tbody", [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "\n                                        d-flex\n                                        align-items-center\n                                        justify-content-between\n                                      "
                                                    },
                                                    [
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          font-size-h5\n                                          mb-0\n                                          font-size-bold\n                                          text-dark\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        SUBTOTAL\n                                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          justify-content-end\n                                          d-flex\n                                          text-black-50\n                                          font-size-base\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.subtotal
                                                              ) +
                                                              "\n                                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "\n                                        d-flex\n                                        align-items-center\n                                        justify-content-between\n                                      "
                                                    },
                                                    [
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          font-size-h5\n                                          mb-0\n                                          font-size-bold\n                                          text-dark\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        TAX\n                                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          justify-content-end\n                                          d-flex\n                                          text-black-50\n                                          font-size-base\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.tax_total
                                                              ) +
                                                              "\n                                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "\n                                        d-flex\n                                        align-items-center\n                                        justify-content-between\n                                      "
                                                    },
                                                    [
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          font-size-h5\n                                          mb-0\n                                          font-size-bold\n                                          text-dark\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        DISCOUNT\n                                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          justify-content-end\n                                          d-flex\n                                          text-black-50\n                                          font-size-base\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.discountTotal
                                                              ) +
                                                              "\n                                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "\n                                        d-flex\n                                        align-items-center\n                                        justify-content-between\n                                        item-price\n                                      "
                                                    },
                                                    [
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          font-size-h5\n                                          mb-0\n                                          font-size-bold\n                                          text-dark\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        TOTAL\n                                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          justify-content-end\n                                          d-flex\n                                          text-dark\n                                          font-size-base\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.total
                                                              ) +
                                                              "\n                                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane fade",
                      attrs: {
                        id: "invoice",
                        role: "tabpanel",
                        "aria-labelledby": "invoice-tab"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card card-custom gutter-b bg-white border-0"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                        card-header\n                        d-flex\n                        align-items-start\n                        justify-content-between\n                        border-0\n                        pb-5\n                      "
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "card-title mb-0 d-flex inovice-main"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "\n                            bg-primary\n                            pb-3\n                            h-150px\n                            w-150px\n                            d-flex\n                            flex-column\n                            justify-content-end\n                            align-items-center\n                          "
                                    },
                                    [
                                      _c(
                                        "h3",
                                        {
                                          staticClass: "font-size-h3 text-white"
                                        },
                                        [_vm._v("Invoice")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "h4",
                                        {
                                          staticClass: "font-size-h4 text-white"
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(_vm.orders.order_id) +
                                              "\n                          "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "card-user-detail padding-top pl-3 w-250px"
                                    },
                                    [
                                      _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "font-size-h5 font-size-bold text-body"
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                this.$parent.globalSettings
                                                  .site_name
                                              ) +
                                              "\n                          "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "h5",
                                        {
                                          staticClass: "font-size-h5 text-dark"
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.orders
                                                  .delivery_street_aadress
                                              ) +
                                              "\n                          "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "h5",
                                        {
                                          staticClass: "font-size-h5 text-dark"
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.orders.customer_id
                                                  .delivery_phone
                                              ) +
                                              "\n                          "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "h5",
                                        {
                                          staticClass: "font-size-h5 text-dark"
                                        },
                                        [
                                          _vm._v(
                                            "\n                            " +
                                              _vm._s(
                                                _vm.orders.customer_id
                                                  .customer_email
                                              ) +
                                              "\n                          "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "padding-top" }, [
                                _c(
                                  "h5",
                                  { staticClass: "font-size-h5 text-black-50" },
                                  [
                                    _vm._v(
                                      "\n                          Placed On "
                                    ),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.orders.order_date))
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c("a", { attrs: { href: "#" } }, [
                                  _c("img", {
                                    attrs: {
                                      src: this.$parent.globalSettings
                                        .site_logo,
                                      alt: "logo"
                                    }
                                  })
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-body" }, [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-12 table-padding" },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-striped text-body"
                                    },
                                    [
                                      _vm._m(2),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        { attrs: { id: "order-show-detail" } },
                                        [
                                          _vm._l(
                                            _vm.orders.order_detail,
                                            function(detail) {
                                              return detail.product
                                                .product_type == "variable"
                                                ? _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "d-flex responsive-lay"
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "col-12 col-md-2"
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "img-fluid order-image",
                                                            staticStyle: {
                                                              width: "50px",
                                                              height: "60px"
                                                            },
                                                            attrs: {
                                                              src:
                                                                "/gallary/" +
                                                                detail
                                                                  .product_combination
                                                                  .gallary
                                                                  .gallary_name,
                                                              alt:
                                                                "/gallary/" +
                                                                detail
                                                                  .product_combination
                                                                  .gallary
                                                                  .gallary_name
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "col-12 col-md-3 item-detail-left"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-body"
                                                            },
                                                            [
                                                              _c(
                                                                "h4",
                                                                {
                                                                  staticClass:
                                                                    "order-product-name"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                      " +
                                                                      _vm._s(
                                                                        detail
                                                                          .product
                                                                          .detail[0]
                                                                          .title
                                                                      ) +
                                                                      "\n                                      "
                                                                  ),
                                                                  _vm._l(
                                                                    detail
                                                                      .product_combination
                                                                      .combination,
                                                                    function(
                                                                      variation
                                                                    ) {
                                                                      return _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                        " +
                                                                              _vm._s(
                                                                                variation
                                                                                  .variation
                                                                                  .detail[0]
                                                                                  .name
                                                                              ) +
                                                                              "\n                                      "
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "tag-color col-12 col-md-3 order-price"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                  " +
                                                              _vm._s(
                                                                detail.product_discount
                                                                  ? detail.product_discount
                                                                  : detail.product_price
                                                              ) +
                                                              "}\n                                "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "col-12 col-md-2"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-group"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                    " +
                                                                  _vm._s(
                                                                    detail.product_qty
                                                                  ) +
                                                                  "\n                                  "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "tag-s col-12 col-md-2 order-sub-price"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                 " +
                                                              _vm._s(
                                                                detail.product_discount
                                                                  ? detail.product_discount *
                                                                      detail.product_qty
                                                                  : detail.product_price *
                                                                      detail.product_qty
                                                              ) +
                                                              "\n                                "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.orders.order_detail,
                                            function(detail) {
                                              return detail.product
                                                .product_type == "simple"
                                                ? _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "d-flex responsive-lay"
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "col-12 col-md-2"
                                                        },
                                                        [
                                                          _c("img", {
                                                            staticClass:
                                                              "img-fluid order-image",
                                                            staticStyle: {
                                                              width: "50px",
                                                              height: "60px"
                                                            },
                                                            attrs: {
                                                              src:
                                                                "/gallary/" +
                                                                detail.product
                                                                  .product_gallary
                                                                  .gallary_name,
                                                              alt:
                                                                "/gallary/" +
                                                                detail.product
                                                                  .product_gallary
                                                                  .gallary_name
                                                            }
                                                          })
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "col-12 col-md-3 item-detail-left"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-body"
                                                            },
                                                            [
                                                              _c(
                                                                "h4",
                                                                {
                                                                  staticClass:
                                                                    "order-product-name"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                      " +
                                                                      _vm._s(
                                                                        detail
                                                                          .product
                                                                          .detail[0]
                                                                          .title
                                                                      ) +
                                                                      "\n                                    "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "tag-color col-12 col-md-3 order-price"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                  " +
                                                              _vm._s(
                                                                detail.product_discount
                                                                  ? detail.product_discount
                                                                  : detail.product_price
                                                              ) +
                                                              "\n                                "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "col-12 col-md-2"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input-group"
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                    " +
                                                                  _vm._s(
                                                                    detail.product_qty
                                                                  ) +
                                                                  "\n                                  "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "tag-s col-12 col-md-2 order-sub-price"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                  " +
                                                              _vm._s(
                                                                detail.product_discount
                                                                  ? detail.product_discount *
                                                                      detail.product_qty
                                                                  : detail.product_price *
                                                                      detail.product_qty
                                                              ) +
                                                              "\n                                "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "row justify-content-end" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "col-12 col-md-3" },
                                        [
                                          _c("div", [
                                            _c(
                                              "table",
                                              {
                                                staticClass: "table right-table"
                                              },
                                              [
                                                _c("tbody", [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "\n                                        d-flex\n                                        align-items-center\n                                        justify-content-between\n                                      "
                                                    },
                                                    [
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          font-size-h5\n                                          mb-0\n                                          font-size-bold\n                                          text-dark\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        SUBTOTAL\n                                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          justify-content-end\n                                          d-flex\n                                          text-black-50\n                                          font-size-base\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.subtotal
                                                              ) +
                                                              "\n                                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "\n                                        d-flex\n                                        align-items-center\n                                        justify-content-between\n                                      "
                                                    },
                                                    [
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          font-size-h5\n                                          mb-0\n                                          font-size-bold\n                                          text-dark\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        TAX\n                                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          justify-content-end\n                                          d-flex\n                                          text-black-50\n                                          font-size-base\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.tax_total
                                                              ) +
                                                              "\n                                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "\n                                        d-flex\n                                        align-items-center\n                                        justify-content-between\n                                      "
                                                    },
                                                    [
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          font-size-h5\n                                          mb-0\n                                          font-size-bold\n                                          text-dark\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        DISCOUNT\n                                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          justify-content-end\n                                          d-flex\n                                          text-black-50\n                                          font-size-base\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.discountTotal
                                                              ) +
                                                              "\n                                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticClass:
                                                        "\n                                        d-flex\n                                        align-items-center\n                                        justify-content-between\n                                        item-price\n                                      "
                                                    },
                                                    [
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          font-size-h5\n                                          mb-0\n                                          font-size-bold\n                                          text-dark\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        TOTAL\n                                      "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "\n                                          border-0\n                                          justify-content-end\n                                          d-flex\n                                          text-dark\n                                          font-size-base\n                                        "
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                        " +
                                                              _vm._s(
                                                                _vm.total
                                                              ) +
                                                              "\n                                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane fade status-tab-style",
                      attrs: {
                        id: "status",
                        role: "tabpanel",
                        "aria-labelledby": "status-tab"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card card-custom gutter-b bg-white border-0"
                        },
                        [
                          _c("div", { staticClass: "card-body" }, [
                            _c("div", { staticClass: "row gutter-b" }, [
                              _c(
                                "div",
                                { staticClass: "col-12 table-padding" },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticClass:
                                        "table table-striped text-body"
                                    },
                                    [
                                      _vm._m(3),
                                      _vm._v(" "),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.orders.order_history,
                                          function(history) {
                                            return _c(
                                              "tr",
                                              { staticClass: "row" },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-center col-6"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                  " +
                                                        _vm._s(
                                                          history.created_at
                                                        ) +
                                                        "\n                                "
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "text-center col-6"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                  " +
                                                        _vm._s(
                                                          history.order_status
                                                        ) +
                                                        "\n                                "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "\n                              form-group\n                              row\n                              align-items-center\n                              justify-content-center\n                            "
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-xl-6 col-lg-8 col-12 status-detail"
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "col-form-label mr-3"
                                          },
                                          [_vm._v("Order Status")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "fieldset",
                                          { staticClass: "form-group" },
                                          [
                                            _vm.order_status == "Pending"
                                              ? _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.order_status,
                                                        expression:
                                                          "order_status"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    on: {
                                                      change: function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.order_status = $event
                                                          .target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Pending"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Pending"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                  \n                                    Pending\n                                  "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Inprocess"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Inprocess"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                  \n                                    In Process\n                                  "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Dispatched"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Dispatched"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                  \n                                    Dispatched\n                                  "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Complete"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Complete"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    Complete\n                                  "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Return"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Return"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    Return\n                                  "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Cancel"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Cancel"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    Cancel\n                                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.order_status == "Inprocess"
                                              ? _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.order_status,
                                                        expression:
                                                          "order_status"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    on: {
                                                      change: function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.order_status = $event
                                                          .target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Inprocess"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Inprocess"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                  \n                                    In Process\n                                  "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Dispatched"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Dispatched"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                  \n                                    Dispatched\n                                  "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Complete"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Complete"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    Complete\n                                  "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Return"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Return"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    Return\n                                  "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Cancel"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Cancel"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    Cancel\n                                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.order_status == "Dispatched"
                                              ? _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.order_status,
                                                        expression:
                                                          "order_status"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    on: {
                                                      change: function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.order_status = $event
                                                          .target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Dispatched"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Dispatched"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                  \n                                    Dispatched\n                                  "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Complete"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Complete"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    Complete\n                                  "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Return"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Return"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    Return\n                                  "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Cancel"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Cancel"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    Cancel\n                                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.order_status == "Complete"
                                              ? _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.order_status,
                                                        expression:
                                                          "order_status"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    on: {
                                                      change: function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.order_status = $event
                                                          .target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Complete"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Complete"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    Complete\n                                  "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Return"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Return"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    Return\n                                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.order_status == "Cancel"
                                              ? _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.order_status,
                                                        expression:
                                                          "order_status"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    on: {
                                                      change: function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.order_status = $event
                                                          .target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Complete"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Cancel"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    Cancel\n                                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.order_status == "Return"
                                              ? _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: _vm.order_status,
                                                        expression:
                                                          "order_status"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    on: {
                                                      change: function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.order_status = $event
                                                          .target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Return"
                                                        },
                                                        domProps: {
                                                          selected:
                                                            _vm.order_status ==
                                                            "Return"
                                                              ? "selected"
                                                              : ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    Return\n                                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("br"),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass: "btn btn-primary",
                                                staticStyle: { float: "right" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.statusUpdate()
                                                  }
                                                }
                                              },
                                              [_vm._v("Submit")]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.setting.is_deliveryboyapp_purchased === "1"
                    ? _c(
                        "div",
                        {
                          staticClass: "tab-pane fade",
                          attrs: {
                            id: "delivery",
                            role: "tabpanel",
                            "aria-labelledby": "delivery-tab"
                          }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-lg-12 col-12" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "card card-custom gutter-b bg-white border-0"
                                },
                                [
                                  _c("div", { staticClass: "card-body" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c("div", { staticClass: "col-12" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "\n                              form-group\n                              row\n                              align-items-center\n                              justify-content-center\n                            "
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-xl-6 col-lg-8 col-12 status-detail"
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "col-form-label mr-3"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Assign Order To Delivery Boy"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "fieldset",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.delivery_boy_id,
                                                            expression:
                                                              "delivery_boy_id"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$selectedVal = Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function(o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function(o) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                            _vm.delivery_boy_id = $event
                                                              .target.multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          }
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.deliveryboys,
                                                        function(db) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              domProps: {
                                                                selected:
                                                                  _vm.orders
                                                                    .delivery_boy_id ==
                                                                  db.id,
                                                                value: db.id
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                    " +
                                                                  _vm._s(
                                                                    db.first_name
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    db.last_name
                                                                  ) +
                                                                  "\n                                  "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                    _vm._v(" "),
                                                    _c("br"),
                                                    _vm._v(" "),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-primary",
                                                        staticStyle: {
                                                          float: "right"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.assignDeliveryBoy()
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Submit")]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ])
                                  ])
                                ]
                              )
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane fade",
                      attrs: {
                        id: "notes",
                        role: "tabpanel",
                        "aria-labelledby": "notes-tab"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "card card-custom gutter-b bg-white border-0"
                        },
                        [
                          _c("div", { staticClass: "card-body" }, [
                            _c(
                              "div",
                              {
                                staticClass: "gutter-t",
                                attrs: { id: "draggable-cards" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "row",
                                    attrs: { id: "card-drag-area" }
                                  },
                                  [
                                    _vm._l(_vm.orders.order_notes, function(
                                      note
                                    ) {
                                      return _c(
                                        "div",
                                        { staticClass: "col-lg-6 col-xl-4" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "\n                                card card-custom\n                                gutter-b\n                                bg-white\n                                border-0\n                                pincard\n                              ",
                                              attrs: { id: "02" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "card-header align-items-center border-0"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "card-title mb-0"
                                                    },
                                                    [
                                                      _c(
                                                        "h5",
                                                        {
                                                          staticClass:
                                                            "font-size-h5 text-black-50"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                    " +
                                                              _vm._s(
                                                                note.created_at
                                                              ) +
                                                              "\n                                  "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", {
                                                    staticClass: "pin-click",
                                                    attrs: { id: "pin2" }
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "card-body" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "card-title mb-0"
                                                    },
                                                    [
                                                      _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "font-size-h3 text-body"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                    " +
                                                              _vm._s(
                                                                note.title
                                                              ) +
                                                              "\n                                  "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "card-detail text-dark"
                                                    },
                                                    [
                                                      _c("p", [
                                                        _vm._v(
                                                          "\n                                   " +
                                                            _vm._s(note.notes) +
                                                            "\n\n                                  "
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-lg-6 col-xl-4" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "\n                                border-dashed\n                                addnote\n                                pincard\n                                d-flex\n                                justify-content-center\n                                align-items-center\n                              "
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "card-toolbar",
                                                on: {
                                                  click: function($event) {
                                                    _vm.display_form = !_vm.display_form
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "\n                                    btn btn-icon btn-secondary btn-plus\n                                    h-100px\n                                    w-100px\n                                  ",
                                                    attrs: {
                                                      id:
                                                        "kt_notes_panel_toggle",
                                                      "data-toggle": "tooltip",
                                                      title: "",
                                                      "data-placement": "right",
                                                      "data-original-title":
                                                        "Check out more demos"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "\n                                      svg-icon svg-icon-xl svg-icon-primary\n                                      white\n                                    "
                                                      },
                                                      [
                                                        _c(
                                                          "svg",
                                                          {
                                                            staticClass:
                                                              "bi bi-plus",
                                                            attrs: {
                                                              width: "",
                                                              height: "",
                                                              viewBox:
                                                                "0 0 16 16",
                                                              fill:
                                                                "currentColor",
                                                              xmlns:
                                                                "http://www.w3.org/2000/svg"
                                                            }
                                                          },
                                                          [
                                                            _c("path", {
                                                              attrs: {
                                                                "fill-rule":
                                                                  "evenodd",
                                                                d:
                                                                  "M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "h5",
                                                      {
                                                        staticClass:
                                                          "mt-3 text-dark font-size-h5"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    Add Notes\n                                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ],
                                  2
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane fade",
                      attrs: {
                        id: "message",
                        role: "tabpanel",
                        "aria-labelledby": "message-tab"
                      }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                          card card-custom\n                          gutter-b\n                          bg-white\n                          border-0\n                          message-content\n                          p-5\n                        "
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row justify-content-center" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-md-8" },
                                    _vm._l(_vm.orders.order_comments, function(
                                      comment
                                    ) {
                                      return _c(
                                        "div",
                                        {
                                          staticClass:
                                            "\n                                d-flex\n                                align-items-center\n                                border-0\n                                p-3\n                                rounded\n                                mb-5\n                              "
                                        },
                                        [
                                          _c("div", { staticClass: "media" }, [
                                            comment.customer != null
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "media-body ml-3"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-between mb-1"
                                                      },
                                                      [
                                                        _c(
                                                          "h3",
                                                          {
                                                            staticClass:
                                                              "font-size-h3 text-dark info-tag"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                      " +
                                                                _vm._s(
                                                                  comment
                                                                    .customer
                                                                    .customer_first_name
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  comment
                                                                    .customer
                                                                    .customer_last_name
                                                                ) +
                                                                "\n                                    "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "\n                                      badge badge-primary\n                                      font-size-h5\n                                      rounded\n                                    "
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                    Customer\n                                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            comment.user != null
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "media-body ml-3"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-between mb-1"
                                                      },
                                                      [
                                                        _c(
                                                          "h3",
                                                          {
                                                            staticClass:
                                                              "font-size-h3 text-dark info-tag"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                      " +
                                                                _vm._s(
                                                                  comment.user
                                                                    .name
                                                                ) +
                                                                "\n                                    "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "\n                                      badge badge-primary\n                                      font-size-h5\n                                      rounded\n                                    "
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                  " +
                                                            _vm._s(
                                                              comment.user.role
                                                                .name
                                                            ) +
                                                            "\n\n                                  "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-input position-relative disabled"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "date-text" },
                                                [
                                                  _c(
                                                    "h5",
                                                    {
                                                      staticClass:
                                                        "\n                                      font-size-h5\n                                      text-black-50 text-right\n                                    "
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    " +
                                                          _vm._s(
                                                            comment.created_at
                                                          ) +
                                                          " \n                                  "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "fieldset",
                                                {
                                                  staticClass:
                                                    "form-label-group"
                                                },
                                                [
                                                  _c("textarea", {
                                                    staticClass:
                                                      "form-control fixed-size",
                                                    attrs: {
                                                      id: "horizontalTextarea1",
                                                      rows: "6",
                                                      placeholder:
                                                        comment.message,
                                                      disabled: ""
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "row justify-content-center" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 col-md-8" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "\n                                card-header\n                                align-items-end\n                                border-0\n                                rounded\n                                position-relative\n                              "
                                        },
                                        [
                                          _c(
                                            "fieldset",
                                            {
                                              staticClass:
                                                "form-label-group w-100"
                                            },
                                            [
                                              _c("textarea", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.message,
                                                    expression: "message"
                                                  }
                                                ],
                                                staticClass:
                                                  "\n                                    bg-light\n                                    autoexpand-textarea\n                                    form-control\n                                  ",
                                                attrs: {
                                                  id: "txtarea",
                                                  placeholder: " Enter Text.."
                                                },
                                                domProps: {
                                                  value: _vm.message
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.message =
                                                      $event.target.value
                                                  }
                                                }
                                              }),
                                              _vm._v(" "),
                                              _vm.errors.has("message")
                                                ? _c("small", {
                                                    staticClass:
                                                      "form-text text-danger",
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.errors.get(
                                                          "message"
                                                        )
                                                      )
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "addclip-input pr-3 pb-2"
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-primary",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.saveComments()
                                                    }
                                                  }
                                                },
                                                [_vm._v("Send")]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.display_form == 1
      ? _c(
          "div",
          {
            staticClass:
              "offcanvas offcanvas-right kt-color-panel p-5 offcanvas-on",
            attrs: { id: "kt_notes_panel" }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "offcanvas-header d-flex align-items-center justify-content-between pb-3"
              },
              [
                _c("h4", { staticClass: "font-size-h4 font-weight-bold m-0" }, [
                  _vm._v("Add Note\n\t\t\t")
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "btn btn-sm btn-icon btn-light btn-hover-primary",
                    attrs: { href: "#", id: "kt_notes_panel_close" },
                    on: {
                      click: function($event) {
                        _vm.display_form = !_vm.display_form
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "bi bi-x",
                        attrs: {
                          width: "20px",
                          height: "20px",
                          viewBox: "0 0 16 16",
                          fill: "currentColor",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d:
                              "M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("form", { attrs: { id: "myform", novalidate: "novalidate" } }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  {
                    staticClass: "text-dark",
                    attrs: { for: "exampleInputText1" }
                  },
                  [_vm._v("Title of a Note")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.notes_title,
                      expression: "notes_title"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    name: "text",
                    id: "exampleInputText1",
                    "aria-describedby": "textHelp"
                  },
                  domProps: { value: _vm.notes_title },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.notes_title = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("title")
                  ? _c("small", {
                      staticClass: "form-text text-danger",
                      domProps: { textContent: _vm._s(_vm.errors.get("title")) }
                    })
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { staticClass: "text-dark" }, [_vm._v("Note")]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.notes,
                      expression: "notes"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    id: "label-textarea2",
                    rows: "6",
                    placeholder: "Write A Text"
                  },
                  domProps: { value: _vm.notes },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.notes = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("notes")
                  ? _c("small", {
                      staticClass: "form-text text-danger",
                      domProps: { textContent: _vm._s(_vm.errors.get("notes")) }
                    })
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.saveNotes()
                    }
                  }
                },
                [_vm._v("Add Note")]
              )
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-2 col-12" }, [
      _c("div", { staticClass: "card-title mb-2" }, [
        _c(
          "h3",
          { staticClass: "card-label mb-0 font-weight-bold text-body" },
          [
            _vm._v(
              "\n                            Customer\n                          "
            )
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "d-flex" }, [
        _c("th", { staticClass: "col-2" }),
        _vm._v(" "),
        _c("th", { staticClass: "col-3" }, [_vm._v('ITEM"S')]),
        _vm._v(" "),
        _c("th", { staticClass: "col-3" }, [_vm._v("PRICE")]),
        _vm._v(" "),
        _c("th", { staticClass: "col-2" }, [_vm._v("QTY")]),
        _vm._v(" "),
        _c("th", { staticClass: "col-2" }, [_vm._v("SUBTOTAL")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "d-flex" }, [
        _c("th", { staticClass: "col-2" }),
        _vm._v(" "),
        _c("th", { staticClass: "col-3" }, [_vm._v('ITEM"S')]),
        _vm._v(" "),
        _c("th", { staticClass: "col-3" }, [_vm._v("PRICE")]),
        _vm._v(" "),
        _c("th", { staticClass: "col-2" }, [_vm._v("QTY")]),
        _vm._v(" "),
        _c("th", { staticClass: "col-2" }, [_vm._v("SUBTOTAL")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticClass: "row" }, [
        _c(
          "th",
          {
            staticClass: "text-center border-0 col-6",
            attrs: { scope: "col" }
          },
          [
            _vm._v(
              "\n                                  DATE ADDED\n                                "
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "text-center border-0 col-6",
            attrs: { scope: "col" }
          },
          [
            _vm._v(
              "\n                                  STATUS\n                                "
            )
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }