var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("div", { staticClass: "se-pre-con" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("MobileHeader"),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-column flex-root" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-row flex-column-fluid page" },
          [
            _c("Sidebar", { attrs: { burgerMenu: _vm.burgerMenu } }),
            _vm._v(" "),
            _c("div", {
              staticClass: "aside-overlay",
              class: _vm.burgerMenu ? "active" : ""
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex flex-column flex-row-fluid wrapper",
                attrs: { id: "tc_wrapper" }
              },
              [
                _c("Header", { on: { setBurgerMenu: _vm.setBurgerMenu } }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "content d-flex flex-column flex-column-fluid",
                    attrs: { id: "tc_content" }
                  },
                  [
                    _c("BreadCrumb"),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex flex-column-fluid" }, [
                      _c("div", { staticClass: "container-fluid" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c("router-view", {
                                attrs: {
                                  loading: 1,
                                  permissions: _vm.permissions,
                                  warehouse: "warehouse",
                                  globalSettings: "globalSettings"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pre-loader" }, [
      _c("img", {
        staticClass: "img-fluid",
        attrs: { src: "/assets/images/loadergif.gif", alt: "loading" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }